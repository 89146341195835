<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10 min-height10">
        <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left">
          <el-tab-pane name="todo">
            <span slot="label">待办<el-badge :value="todoNum"/></span>
            <el-row type="flex" justify="end">
              <el-col :xs="6" :sm="5" :md="5" :lg="5" :xl="5">
                <el-button
                  type="success"
                  size="mini"
                  style="margin: 10px"
                  @click="approvalAgreeBatch"
                  >agree</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  style="margin: 10px"
                  @click="approvalRejectBatch"
                  >reject</el-button
                >
              </el-col>
            </el-row>
            <el-table
              :data="todoList.list"
              row-key="id"
              v-loading="loading.todo"
              ref="todo"
              size="mini"
              stripe
              border
              style="width: 100%"
              class="mb10"
              @row-click="todoDetail"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column
                label="souerceName"
                prop="company"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="appName"
                ><template slot-scope="scope">{{ scope.row.appName }}</template></el-table-column
              >
              <el-table-column label="offer"
                ><template slot-scope="scope">{{ scope.row.offer }}</template></el-table-column
              >
              <el-table-column label="payable"
                ><template slot-scope="scope">{{ scope.row.payable }}</template></el-table-column
              >
              <el-table-column label="total"
                ><template slot-scope="scope">{{
                  scope.row.totalAmount
                }}</template></el-table-column
              >
              <el-table-column label="deduction"
                ><template slot-scope="scope">{{ scope.row.deduction }}</template></el-table-column
              >
              <el-table-column label="cover"
                ><template slot-scope="scope">{{ scope.row.cover }}</template></el-table-column
              >
              <el-table-column label="reason"
                ><template slot-scope="scope">{{ scope.row.reason }}</template></el-table-column
              >
              <el-table-column label="createBy"
                ><template slot-scope="scope">{{ scope.row.createBy }}</template></el-table-column
              >
              <el-table-column label="createTime"
                ><template slot-scope="scope">{{ scope.row.createTime }}</template></el-table-column
              >
              <el-table-column label="status"
                ><template slot-scope="scope">{{
                  formatValue(scope.row.status)
                }}</template></el-table-column
              >
              <el-table-column label="Action" align="center" fixed="right" width="auto">
                <template slot-scope="scope">
                  <!-- <el-button size="mini" type="success" @click="openAgreeApproval(scope.row.id)"
                    >agree</el-button
                  >
                  <el-button type="primary" size="mini" @click="todoDetail(scope.row)"
                    >detail</el-button
                  > -->
                  <div style="display: flex; justify-content: center">
                    <el-button size="mini" type="success" @click="openAgreeApproval(scope.row.id)"
                      >agree</el-button
                    >
                    <el-button type="primary" size="mini" @click="todoDetail(scope.row)"
                      >detail</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-pagination">
              <el-pagination
                :layout="paginations.layout"
                @prev-click="(page) => pageChange(page, 'todo')"
                @next-click="(page) => pageChange(page, 'todo')"
                @current-change="(page) => pageChange(page, 'todo')"
                @size-change="(size) => pageSizeChange(size, 'todo')"
                :page-size.sync="todoList.pagesize"
                :page-sizes="paginations.pageSizes"
                :current-page="todoList.page"
                :total="todoList.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane name="completed">
            <span slot="label">已审批<el-badge :value="completedNum"/></span>
            <el-table
              :data="completedList.list"
              row-key="id"
              v-loading="loading.completed"
              ref="completed"
              size="mini"
              stripe
              border
              @row-click="seeDetail"
            >
              <el-table-column
                label="sourceName"
                prop="company"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="appName"
                ><template slot-scope="scope">{{ scope.row.appName }}</template></el-table-column
              >
              <el-table-column label="offer"
                ><template slot-scope="scope">{{ scope.row.offer }}</template></el-table-column
              >
              <el-table-column label="payable"
                ><template slot-scope="scope">{{ scope.row.payable }}</template></el-table-column
              >
              <el-table-column label="total"
                ><template slot-scope="scope">{{
                  scope.row.totalAmount
                }}</template></el-table-column
              >
              <el-table-column label="deduction"
                ><template slot-scope="scope">{{ scope.row.deduction }}</template></el-table-column
              >
              <el-table-column label="cover"
                ><template slot-scope="scope">{{ scope.row.cover }}</template></el-table-column
              >
              <el-table-column label="reason"
                ><template slot-scope="scope">{{ scope.row.reason }}</template></el-table-column
              >
              <el-table-column label="createBy"
                ><template slot-scope="scope">{{ scope.row.createBy }}</template></el-table-column
              >
              <el-table-column label="createTime"
                ><template slot-scope="scope">{{ scope.row.createTime }}</template></el-table-column
              >
              <el-table-column label="status"
                ><template slot-scope="scope">{{
                  formatValue(scope.row.status)
                }}</template></el-table-column
              >
              <el-table-column label="Action"
                ><template slot-scope="scope"
                  ><el-button type="primary" size="mini" @click="seeDetail(scope.row)"
                    >detail</el-button
                  ></template
                ></el-table-column
              >
            </el-table>
            <div class="table-pagination">
              <el-pagination
                :layout="paginations.layout"
                @prev-click="(page) => pageChange(page, 'completed')"
                @next-click="(page) => pageChange(page, 'completed')"
                @current-change="(page) => pageChange(page, 'completed')"
                @size-change="(size) => pageSizeChange(size, 'completed')"
                :page-size.sync="completedList.pagesize"
                :page-sizes="paginations.pageSizes"
                :current-page="completedList.page"
                :total="completedList.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="抄送我的" name="cc">
            <span slot="label">抄送我的<el-badge :value="ccNum"/></span>
            <el-table
              :data="ccList.list"
              row-key="id"
              v-loading="loading.cc"
              ref="cc"
              size="mini"
              class="mb10"
              stripe
              border
              @row-click="seeDetail"
            >
              <el-table-column
                label="sourceName"
                prop="company"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="appName"
                ><template slot-scope="scope">{{ scope.row.appName }}</template></el-table-column
              >
              <el-table-column label="offer"
                ><template slot-scope="scope">{{ scope.row.offer }}</template></el-table-column
              >
              <el-table-column label="payable"
                ><template slot-scope="scope">{{ scope.row.payable }}</template></el-table-column
              >
              <el-table-column label="total"
                ><template slot-scope="scope">{{
                  scope.row.totalAmount
                }}</template></el-table-column
              >
              <el-table-column label="deduction"
                ><template slot-scope="scope">{{ scope.row.deduction }}</template></el-table-column
              >
              <el-table-column label="cover"
                ><template slot-scope="scope">{{ scope.row.cover }}</template></el-table-column
              >
              <el-table-column label="reason"
                ><template slot-scope="scope">{{ scope.row.reason }}</template></el-table-column
              >
              <el-table-column label="createBy"
                ><template slot-scope="scope">{{ scope.row.createBy }}</template></el-table-column
              >
              <el-table-column label="createTime"
                ><template slot-scope="scope">{{ scope.row.createTime }}</template></el-table-column
              >
              <el-table-column label="status"
                ><template slot-scope="scope">{{
                  formatValue(scope.row.status)
                }}</template></el-table-column
              >
              <el-table-column label="Action"
                ><template slot-scope="scope"
                  ><el-button type="primary" size="mini" @click="seeDetail(scope.row)"
                    >detail</el-button
                  ></template
                ></el-table-column
              >
            </el-table>
            <div class="table-pagination">
              <el-pagination
                :layout="paginations.layout"
                @prev-click="(page) => pageChange(page, 'cc')"
                @next-click="(page) => pageChange(page, 'cc')"
                @current-change="(page) => pageChange(page, 'cc')"
                @size-change="(size) => pageSizeChange(size, 'cc')"
                :page-size.sync="ccList.pagesize"
                :page-sizes="paginations.pageSizes"
                :current-page="ccList.page"
                :total="ccList.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我发起的" name="apply">
            <span slot="label">我发起的<el-badge :value="launchNum"/></span>
            <el-table
              :data="applyList.list"
              row-key="id"
              v-loading="loading.apply"
              ref="apply"
              size="mini"
              stripe
              border
              @row-click="seeDetail"
            >
              <el-table-column
                label="sourceName"
                prop="company"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="appName"
                ><template slot-scope="scope">{{ scope.row.appName }}</template></el-table-column
              >
              <el-table-column label="offer"
                ><template slot-scope="scope">{{ scope.row.offer }}</template></el-table-column
              >
              <el-table-column label="payable"
                ><template slot-scope="scope">{{ scope.row.payable }}</template></el-table-column
              >
              <el-table-column label="total"
                ><template slot-scope="scope">{{
                  scope.row.totalAmount
                }}</template></el-table-column
              >
              <el-table-column label="deduction"
                ><template slot-scope="scope">{{ scope.row.deduction }}</template></el-table-column
              >
              <el-table-column label="cover"
                ><template slot-scope="scope">{{ scope.row.cover }}</template></el-table-column
              >
              <el-table-column label="reason"
                ><template slot-scope="scope">{{ scope.row.reason }}</template></el-table-column
              >
              <el-table-column label="createBy"
                ><template slot-scope="scope">{{ scope.row.createBy }}</template></el-table-column
              >
              <el-table-column label="createTime"
                ><template slot-scope="scope">{{ scope.row.createTime }}</template></el-table-column
              >
              <el-table-column label="status"
                ><template slot-scope="scope">{{
                  formatValue(scope.row.status)
                }}</template></el-table-column
              >
              <el-table-column label="Action"
                ><template slot-scope="scope"
                  ><el-button type="primary" size="mini" @click="seeDetail(scope.row)"
                    >detail</el-button
                  ></template
                ></el-table-column
              >
            </el-table>
            <div class="table-pagination">
              <el-pagination
                :layout="paginations.layout"
                @prev-click="(page) => pageChange(page, 'apply')"
                @next-click="(page) => pageChange(page, 'apply')"
                @current-change="(page) => pageChange(page, 'apply')"
                @size-change="(size) => pageSizeChange(size, 'apply')"
                :page-size.sync="applyList.pagesize"
                :current-page="applyList.page"
                :page-sizes="paginations.pageSizes"
                :total="applyList.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <el-drawer
      :title="drawerShow.title"
      :visible.sync="drawerShow.visible"
      direction="rtl"
      :before-close="todoBeforeClose"
    >
      <el-card v-loading="loading.detail">
        <div class="card-div">
          <span class="card-div-left">SourceName</span>
          <span class="card-div-right">: {{ currentDetail.company }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">Presenter</span>
          <span class="card-div-right">: {{ currentDetail.createBy }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">AppName</span>
          <span class="card-div-right">: {{ currentDetail.appName }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">TotalAmount</span>
          <span class="card-div-right">: {{ currentDetail.totalAmount }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">Reason</span>
          <span class="card-div-right">: {{ currentDetail.reason }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">Deduction</span>
          <span class="card-div-right">: {{ currentDetail.deduction }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">Cover</span>
          <span class="card-div-right">: {{ currentDetail.cover }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">Payable</span>
          <span class="card-div-right">: {{ currentDetail.payable }}</span></div
        >
        <div class="card-div">
          <span class="card-div-left">DedcutionRatio</span>
          <span class="card-div-right"
            >: {{ (currentDetail.deductionRatio * 100).toFixed(2) }}%</span
          ></div
        >
        <div class="card-div">
          <span class="card-div-left">CoverRatio</span>
          <span class="card-div-right"
            >: {{ (currentDetail.coverRatio * 100).toFixed(2) }}%</span
          ></div
        >
        <div class="card-div">
          <span class="card-div-left">Note</span>
          <span class="card-div-right">: {{ currentDetail.note }}</span></div
        >
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span>Attachments</span>
          </div>
          <el-collapse v-model="attachmentsIndex">
            <el-collapse-item title="attachments detail" name="1">
              <span
                class="card-div-files"
                v-for="(item, index) in currentDetail.financeAttachments"
                :key="index"
              >
                <div>{{ item.reasonKey }}</div>
                <div
                  ><a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a></div
                >
              </span>
            </el-collapse-item>
          </el-collapse>
        </el-card>

        <el-card>
          <div slot="header" class="clearfix" shadow="hover">
            <span>records</span>
          </div>
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in currentDetail.records"
              :key="index"
              :timestamp="activity.createTime"
            >
              [{{ activity.createBy }}] : {{ activity.note }}
            </el-timeline-item>
          </el-timeline>
        </el-card>
      </el-card>

      <el-card v-if="drawerShow.type == 'todo'" v-loading="loading.detail">
        <div>
          <el-input
            type="textarea"
            v-model="drawerShow.comment"
            placeholder="please add comment"
            maxlength="255"
            show-word-limit
          ></el-input>
        </div>
        <div class="card-btns">
          <el-button type="success" size="mini" @click="doAgreeApproval">agree</el-button>
          <el-button type="danger" size="mini" @click="doRejectApproval">reject</el-button>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
  import financeApproval from '../controllers/finance/financeApproval';
  export default {
    name: 'financeApproval',
    ...financeApproval,
  };
</script>

<style scoped>
  .min-height10 {
    min-height: 93vh;
  }

  .card-div {
    border-bottom: 1px solid rgb(228, 224, 224);
    padding: 10px 5px;
    font-size: 13px;
    color: rgb(71, 66, 66);
  }
  .card-div:hover {
    background-color: rgb(233, 231, 228);
  }
  .no-border {
    border: 0;
  }
  .card-div-left {
    width: 35%;
    display: inline-block;
    padding-bottom: 3px;
  }
  .card-div-files {
    display: block;
    border-radius: 3px;
    margin-top: 5px;
    border: 1px solid rgb(228, 224, 224);
    padding: 5px;
  }
  .card-div-files a {
    color: rgb(119, 173, 243);
  }
  .comment-content {
    padding: 5px;
  }
  .comment-author {
    border-top: 1px solid rgb(228, 224, 224);
    padding: 5px;
    text-align: right;
  }
  .card-btns {
    margin-top: 10px;
  }
  .table-pagination {
    margin-top: 10px;
  }
</style>
